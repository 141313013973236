
 /* ul li:hover{
    background-color:#0086f0;

   
} */
/* .header-nav .nav > li:hover > a:after{
    background-color:#0f328e !important;
}

.construct-header .header-nav .nav > li > a:hover{

    color:#0f328e !important;
    font-weight: 600 !important;
    
}
 .construct-header .header-nav .nav > li > a{
    color: #6c6262 !important;
 }

.login-field-mob{
    display: none;
}
.login-filed-web{
    display: flex;
}
.search-field-mob{
    display: block;
}
.mo-left .header-nav .logo-header {
    display: none;
    float: none;
    height: auto;
    max-width: 100%;
    padding: 30px 25px;
    width: 100%;
}
.login-filed-profile{
    display: inline-block;
}
.logout-web{
padding: 10px ;
font-size: 14px;
}

@media screen and (max-width: 991px) {
  .login-field-mob{
    display: inline-block;
}
.login-filed-web{
    display: none !important;
}
.search-field-mob{
    display: flex;
    justify-content: center;
    margin-right: 20px;

}
.logout-web{
    padding-top: 10px;
    
    font-size: 12px;
    margin-left: 10px;
    margin-right:10px ;
    margin-top: 4px;
}
.login-filed-profile{
    display: none !important;
}
.mo-left .header-nav .logo-header {
    display: none;
    float: none;
    height: auto;
    max-width: 100%;
    padding: 30px 25px;
    width: 100%;
}
} */
.dropbtn {
  background-color: transparent;
  color: black;
  font-size: 16px;
  border: none;
  font-weight: 500;
}

.dropdown {
  position: absolute;

  
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  color: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
 
 
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;

  display: block;
}

.dropdown-content a:hover {background-color: #4cb5e2;
color: #a9adb0;
cursor: pointer;}

.dropdown:hover .dropdown-content {display: block;
position: fixed;
z-index: 8888;}

.dropdown:hover .dropbtn {color: #08338f;}
.header {
    position: fixed;
    height: 80px !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    font-size: 14px;
   
}
.header-bg{
    background-color: #DDF1FA !important;
    box-shadow: 0px 4px 8px 1px #a9adb0
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
        z-index: 999;
    /* padding: 0 1rem; */
}

.header .nav-menu a {
    color: #ffffff;
}

.header .navbar img {
    width: 250px;
    height: auto;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}
@media screen and (max-width:1260px) {
.header {
    position: fixed;
    height: 109px !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
   
}
    .header .navbar img {
        width: 140px !important;
        margin-top: 0px !important;
        margin-left:19px;
    }
    ul{
        padding-left: 0px !important;
    }
}
@media screen and (max-width:1260px) {
    .header {
        position: fixed;
        height: 109px !important;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        transition: .3s ease-in;
        overflow: hidden;
       
    }
        .header .navbar img {
            width: 97px !important;
            margin-top: 0px !important;
            margin-left:19px;
        }
        ul{
            padding-left: 0px !important;
        }
    }
@media screen and (max-width:1050px) {
.header {
    position: fixed;
    height: 109px !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
   
}
    .header .navbar img {
        width: 97px !important;
        margin-top: 0px !important;
        margin-left:19px;
    }
    ul{
    padding-left: 0px !important;
}

}
@media screen and (max-width:1035px) {
    .header {
        position: fixed;
        height: 109px !important;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        transition: .3s ease-in;
        overflow: hidden;
       
    }
        .header .navbar img {
            width: 97px !important;
            margin-top: 0px !important;
            margin-left:19px;
        }
        ul{
        padding-left: 0px !important;
    }
    .header .nav-item {
        padding: 10px !important;
        font-weight: 500;
    }
    }


@media screen and (max-width:1018px) {
    .header .nav-item{
        padding: '0px' !important;
    }
    
    .header {
    position: fixed;
    height: 79px !important;
       max-width: 100%;
        background-color: #DDF1FA !important;
    padding: 0px 20px 0px 20px ;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
   
}

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
       

    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 78px;
        flex-direction: column;
        background-color:#DDF1FA !important;
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 10px;
    }

    .header .navbar img {
        width: 97px !important;
    }
}
















