.deck{
    display: flex;
    justify-content: center;
  }
  .card-space:hover .card1{
    transform: rotateY(-180deg);
  }
  
  .card1{
    border-radius: 10px;
    height: 450px;
    margin: 10px;
    /* width: 100%; */
    position: relative;
    transform-style: preserve-3d;
    transition: 3s all ease;
  }
  .face{
    border-radius: 10px;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.1);    
    background-color: #e9ecef;
    padding: 20px;
    position: absolute;
    height: 100%;
    width: 100%;}
    .front{
      text-align: center;
      z-index: 20;
      backface-visibility: hidden;
      transform: rotateY(0deg);
      
    }
    .back{
      transform: rotateY(180deg);
      backface-visibility: hidden;
    }

    .font{
      font-size: 16px;
     }
    @media only screen and (max-width: 500px) {
     .font{
      font-size: 12px;
     }
    }

    @media only screen and (max-width: 500px) {
      .card1{
        border-radius: 10px;
        height:650px;
        margin: 10px;
        /* width: 100%; */
        position: relative;
        transform-style: preserve-3d;
        transition: 3s all ease;
      }
    }