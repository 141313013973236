.dashboard_container{
    min-height: 90%;
    width: 90vw;
    margin-left: 20%;
    z-index: 1500;
    transition: all 0.5s;
}
.dashboard_container2 {
    min-height: 90%;
    width: 95vw;
    margin-left: 10%;
    z-index: 1500;
    transition: all 0.5s;
}
@media only screen and (max-width: 1024px) {
    .dashboard_container {
            min-height: 90%;
            width: 100%;
            margin-left: 0%;
        }
}





/* -------------------------------------------------sidebar start---------------------------------------  */

.dashItem {
    display: flex;
    z-index: 999px;
    flex-wrap: wrap;
    flex-direction: column;
}

.top_section {
    background: #DDF1FA;
}

.text_css:hover {
    color: #fff !important;
    background: #081a51;
}

.text_css {
    display: flex;
    padding-bottom: 5px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 15px;
    background: none;
    border: none;
    margin-top: 0rem !important;
    margin-left: -2.2rem !important;
}

.text_to_list {
    margin-top: 15px;
    align-items: end;
}

.text_list:hover {
    background: #017efa;
    color: #000;
    transition: all 0.5s;
}

.text_list {
    transition: all 0.5s;
    text-decoration: none;
    font-size: 15px;
    background: none;
    border: none;
    position: relative;
    top: 20px;
    padding: 0%;
    width: 100% !important;
    margin-top: 5px;
}

.sidebar {
    background: #08338F;
    height: 100vh;
    width: 300px;
    transition: all 0.5s;
    position: fixed;
    top: 0;
    left: 0;
}

.smallSidebar {
    display: none !important;

}

.top_section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
}

@media screen and (max-width: 1130px) {
    .sidebar {
        display: none !important;
    }

    .smallSidebar {
        display: block !important;
        position: absolute;
        top: 27px;
        left: 5%;
        z-index: 100;
        position: fixed;
        
    }

    .smallSidebarIcon {
        font-size: 35px;
        position: fixed;
        background: #ffffff;
        top: 0%;
        left: 0%;
        right: 0%;
        /* width: 100%; */
        color: #000;
    }

    .canvascard {
        background: #08338F !important;
        height: 100% !important;
        width: 300px !important;
        transition: all 0.5s !important;
        z-index: 1500 !important;
    }

    .offcanvas-header .btn-close {
        background-color: #ffffff !important;
        border-radius: 50%;
        width: 10px !important;
        height: 10px !important;
        z-index: 1500 !important;

    }
}

.link {
    display: flex;
    padding-left: 20px;
    gap: 25px;
    transition: all 0.5s;
    text-decoration: none;
    font-size: 25px;
    margin-top: 1.5rem !important;
    width: auto;
    margin-left: 12px;
    margin-right: 26px;
    border-radius: 10px;
    padding-bottom: 7px !important;
    color: white;
}

.link:hover {
    text-decoration: none;
    background-color: #1b2b65;
    color: #ffffff !important;
    margin-right: 15px;
}

.link_text {
    display: flex;
    padding-left: 20px;
    padding-top: 10px;
    text-decoration: none;
    font-size: 15px;
    background: none;
    border: none;
    margin-top: 0rem !important;
    margin-left: -2.2rem !important;
}

.link.active {
    background-color: #1b2b65;
    color: #ffffff !important;
    padding-bottom: 7px !important;
    margin-right: 15px;
}

.dropdownData {
    position: absolute;
    top: 160px;
    left: 0;
}

.dropdowntaxData {
    position: absolute;
    top: 210px;
    left: 0;
}

.dropdownItem {
    list-style-type: none;
    transition: all 0.5s;
}

.dropdowntext li a {
    display: flex;
    padding-top: 10px;
    font-size: 15px;
    margin-top: 0rem !important;
}

.dropdowntaxData {
    position: absolute;
    top: 410px;
}

/* -------------------------------------------------Navbar start---------------------------------------  */
.bars {
    /* display: flex; */
    /* font-size: 30px; */
    z-index: 1500;
    margin-left: 25px;
    color: #000 !important;
    background: white;
    border-radius: 50%;
    transition: all 0.5s;
    border: 1px solid #1b2b65;
    position: absolute;
    cursor: pointer;
    top: 70px;
    left: 9px;
    width: 30px;
    height: 30px;
}

.barIcon {
    border-radius: 50px;
    color: #1b2b65 !important;
    font-size: 20px;
    z-index: 1500;
    cursor: pointer;
    /* margin-left: -9px; */
}

.crypNavbar {
    position: absolute;
    top: 0;
    right: 0% !important;
    z-index: 1500;
}

@media screen and (max-width: 799px) {
    .navbar {
        width: 100% !important;
    }
}

.crypDrop {
    color: #000;
    display: flex;
    z-index: 1500;
    position: fixed;
    top: 1% !important;
    right: 0%;
    background: transparent;
    padding: 10px;
}

.Droptext {
    margin-top: 20px;
}

.UserImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.userimageLS {
    margin-right: -20px;
}

.Userbell {
    color: #017efa;
    text-decoration: none;
    width: 30px;
    height: 30px;
    padding: 2px;
    border-radius: 50%;
}

.Userbellnumber {
    padding-left: 3px !important;
    margin-left: 8px !important;
}

.css-to4x1n-MuiBadge-badge {
    font-size: 10px !important;
}

.sidemenu {
    display: none;
}

.rightmenu {
    display: none;
}

.stylemenu {
    display: none;
}

@media screen and (max-width: 999px) {
    .bigsmenu {
        display: none;
    }

    .crypDrop {
        display: none;
    }

    .stylemenu {
        display: block;
    }

    .sidemenu {
        display: block;
        position: fixed;
        right: 0%;
        top: 1%;
    }

    .rightmenu {
        display: block;
        color: #017efa;
        position: fixed;
        right: 15px;
        text-decoration: none;
        width: 60px;
        height: 50px;
        padding-left: 20px;
        padding-right: 10px;
        border-radius: 50%;
        margin-top: 18px;
        cursor: pointer;
    }

    .Usersmall {
        color: #017efa;
        display: block;
        text-decoration: none;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 50%;
        margin-top: -3px;
    }

    .UserImgsmall {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

}