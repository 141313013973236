.hero {
    /* background-color: var(--overlay); */
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;

}

.hero:before {
    content: '';
    background: url('https://images.unsplash.com/photo-1672327339029-50bd8f56b4e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80') no-repeat center center/cover;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow-y: hidden !important;
    /* opacity: 0.7; */
}

/* .hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
} */
.responsive-img {
    display: none;
}

.background_contain {
    background-image: url('../Assets/images/bg_2.jpeg');
    /* margin-top: 50px; */
    background-size: cover;
    /* background-position: center; */
    background-repeat: repeat;
}

.background_contain2 {
    background-image: url('../Assets/images/bg_2.jpeg');
    /* margin-top: 50px; */
    background-size: contain;
    /* background-position: center; */
    background-repeat: repeat;
}

.slider-image {
    height: 100vh;
    background-color: black;
    opacity: 0.7;

}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    top: 50%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #191a33 !important;
    text-align: center;
}

.hero .content p:first-child {
    font-style: italic;
    font-size: .9rem;
}

.hero .content p:nth-child(2) {
    font-size: 1.9rem;
}

.hero .content p:nth-child(3) {
    font-size: 5rem;
    font-weight: 700;
}


.hero .content p:nth-child(4) {
    font-size: 2.2rem;
    font-style: italic;
}

.hero .button {
    margin: 1rem 0;
    width: 300px;
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: #333;
}

.hero .button:hover {
    background-color: var(--secondary-dark);
    border: 1px solid var(--secondary-dark);
}

.about {
    background-color: #f8f8f8;
    margin: auto;
    padding: 8rem 1rem 0 1rem;
    width: 100%;
    overflow: hidden;
}

.about .container {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.about h2 {
    font-size: 3rem;
}

.about .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.about .col-2 p:first-of-type {
    margin: 2rem 0;
}

.about .col-2 p:nth-child(4) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
}

.about button {
    margin-top: 1rem;
}

@media screen and (max-width:940px) {
    .slider-mob {
        margin-top: 50px;
    }

    .slider-image {
        height: auto;
        background-color: black;
        opacity: 0.7;

    }

    .about .container {
        grid-template-columns: 1fr;
    }

    .about .container {
        width: 100%;
    }

    .about .container img {
        padding: 0rem;
        width: 90vw;
    }

    .about .container .col-2 {
        padding: 0;
    }

    .about button {
        margin-bottom: 1rem;
    }
}

.font-change{
    font-size: 50px;
}

@media screen and (max-width:425px) {
.font-change{
    font-size: 20px !important;
}
}






@media screen and (max-width:940px) {
    .hero .content p:nth-child(3) {
        font-size: 2.6rem;
    }

    .hero .content p:nth-child(4) {
        font-size: 1.6rem;
    }
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    /* top: 250px !important;  */
    list-style: none;

}

.star-review {
    margin: 0;
}

.star-review li {
    font-size: 13px;
    color: var(--color-primary);
    display: inline-block;
}

.testimonial-detail {
    padding: 5px;
}

.testimonial-name {
    font-size: 18px;
    font-weight: 500;
}

.testimonial-position {
    font-size: 14px;
}

.testimonial-name,
.testimonial-position {
    display: block;
}

.wrapper {
    position: relative;
    display: inline-block;
}

.blurEffect {
    -webkit-filter: blur(7px);
    -o-filter: blur(7px);
    -moz-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
}

.content {
    position: absolute;
    display: inline-block;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: #FFF;
    width: 100%;
    text-align: center;
    z-index: 999;
}

.rh5v-Overlay_inner {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translateY(-50%);
    margin-left: -30px;
    background-color: #18388d !important;
    border-radius: 10px;
}

.play:hover {
    color: rgb(21, 0, 255);
}

@media only screen and (max-width: 768px) {

    /* .about-bx3 .about-img-box{
        display: none !important;
    } */
    .about-bx3 .about-img-box .about-text p {
        padding-left: 60px;
    }

    .about-bx3 .about-img-box .about-img-one {
        display: none !important;
    }

    .about-bx3 .about-img-box .about-img-one img {
        display: none !important;
    }

    .about-bx3 .about-img-box .about-img-two {
        display: none !important;
    }

    .about-bx3 .about-img-box .about-img-three {
        display: none !important;
    }

    .responsive-img {
        display: block;
    }

}